<template>
  <div class="root" :class="type">
    <img class="quotes-img" :src="require(`../../../assets/img/icons/quotes-${type}.svg`)" :alt="type">
    <img class="icon-img" :src="require(`../../../assets/img/icons/icon-${type}.svg`)" :alt="type">
    <span>
      <slot/>
    </span>
  </div>
</template>
<script>
export default {
  name: "CommunicationRulesItem",
  props: {
    type: {
      type: String,
      default: "success",
      validator(value) {
        return ["success", "error"].includes(value)
      }
    }
  }
}
</script>
<style scoped lang="scss">
.root {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 128px;
  background: #eaeaea;
  font-size: 20px;
  line-height: 32px;
  color: #242B33;
  border-radius: 4px;
  font-weight: normal;
}

.success {
  background: linear-gradient(0deg, rgba(84, 226, 124, 0.25), rgba(84, 226, 124, 0.25)), #FFFFFF;
}
.error {
  background: linear-gradient(0deg, rgba(255, 76, 76, 0.15), rgba(255, 76, 76, 0.15)), #FFFFFF;;
}

img {
  position: absolute;
}

.quotes-img {
  width: 55px;
  height: 42px;
  left: 9px;
  top: 10px;
}
.icon-img {
  width: 48px;
  height: 48px;
  right: 0;
  top: 0;
}

span {
  margin-left: 14px;
}
</style>