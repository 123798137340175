<template>
  <div class="page page__content-wrapper">
    <div class="page__min-container">
      <div class="text__head">
        Написание в тексте
      </div>

      <div class="text__wrapper">
        <template v-for="(item) in textList">
          <template v-if="item.type==='subtitle'">
            <div class="text__subtitle" v-for="text in item.text" :key="text">
              {{ text }}
            </div>
          </template>
          <template v-if="item.type==='text'">
            <div class="text__normal" v-for="text in item.text" :key="text">
              {{ text }}
            </div>
          </template>
        </template>
      </div>

      <div class="content__row" v-for="(row, idx) in rulesItems" :key="idx">
        <div class="content__col" v-for="({type, text}) in row" :key="text">
          <CommunicationRulesItem :type="type">
            {{ text }}
          </CommunicationRulesItem>
        </div>
      </div>
    </div>

    <bottom-nav-block :prevLink="bottomNav.pre" :nextLink="bottomNav.next"/>
  </div>
</template>

<script>
import BottomNavBlock from "@/components/Elements/BottomNavBlock/BottomNavBlock"
import CommunicationRulesItem from "../../../components/Elements/CommunicationRulesItem/CommunicationRulesItem"

export default {
  components: {BottomNavBlock, CommunicationRulesItem},
  data() {
    return {
      textList: [
        {
          type: "subtitle",
          text: [
            "Steor"
          ]
        },
        {
          type: "text",
          text: [
            "Название компании всегда пишется с большой буквы.",
            "Использование прописных букв для написания названия компании внутри наборного текста строго запрещено."
          ]
        }
      ],
      rulesItems: [
        [
          {
            type: "success",
            text: "Steor"
          },
          {
            type: "success",
            text: "Стеор"
          }
        ],
        [
          {
            type: "error",
            text: "STEOR"
          },
          {
            type: "error",
            text: "steor"
          }
        ]
      ],
      bottomNav: {
        pre: {
          url: "/identity/photo-style",
          text: "Фото и видео"
        },
        next: {
          url: "/brand/office-identity",
          text: "Офисная айдентика"
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import "src/assets/style/page-block";
@import "src/assets/style/text-style";
@import "src/assets/style/var-style";

.page {
  margin-bottom: 192px;
}

.text {
  &__wrapper {
    width: 688px;
    margin-bottom: 49px;
  }

  &__head {
    font-size: 26px;
    line-height: 40px;
    margin-bottom: 32px;
    letter-spacing: 0;
  }

  &__subtitle {
    font-size: 20px;
    line-height: 31px;
  }

  &__normal {
    letter-spacing: -0;
    margin-bottom: 23px;
  }
}

.content {
  &__row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 32px;
  }

  &__col {
    width: calc(50% - 16px);
  }
}

@media (max-width: 896px) {
  .text {
    &__wrapper {
      width: 100%;
    }
  }
}

@media (max-width: $mobile) {
  .page {
    margin-bottom: 160px;
  }
  .text {
    &__wrapper {
      margin-bottom: 50px;
    }
    &__head {
      margin-bottom: 22px;
    }
    &__subtitle {
      letter-spacing: -0.3;
    }
    &__normal {
      letter-spacing: -0.1px;
    }
  }
  .content {
    &__row {
      flex-wrap: wrap;
      margin-bottom: 0;
    }
    &__col {
      width: 100%;
      margin-bottom: 32px;
    }
  }
}
</style>